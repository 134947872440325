import React from 'react'
// import { Link } from 'gatsby'
import Whatsapp from '../../images/header/whatsapp.svg'

export function SectionWhatsapp () {
  return (
    <a href='https://api.whatsapp.com/send?phone=551146990011&text=Ol%C3%A1!%20Tenho%20interesse%20no%20CloudOfertas%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!'
      target="_blank"
      style= {{ position: 'fixed', bottom: '15px', right: '15px', zIndex: '100' }} rel="noreferrer">
      <img src={Whatsapp} alt="Icone de Whatsapp"/>
    </a>
  )
}
export default SectionWhatsapp
