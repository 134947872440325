import React, { Fragment } from 'react'

// componentes
import Header from '../Header/header'
import MenuMobile from '../menuMobile/menuMobile'
import SectionWhatsapp from '../SectionWhatsapp'

// styles
import '../../scss/base.scss'
import '../../scss/plugins/bootstrap.scss'
import 'swiper/css/swiper.css'
import Footer from '../Footer/footer'

// fonts
import '../../fonts/Montserrat/Montserrat.css'
import '../../fonts/Roboto/Roboto.css'

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header/>
      <MenuMobile />
      <main>
        { children }
      </main>
      {/* <MenuMobile/> */}
      <Footer />
      <SectionWhatsapp />
    </Fragment>
  )
}

Layout.displayName = 'Layout'
export default Layout
