import React from 'react'
// Styles
import * as styles from './header.module.scss'
import { Link as ScrollerLink } from 'react-scroll'

// Assets
import logoCloudofertas from '../../images/header/logo-cloudofertas 1.svg'

const HeaderTop = () => {
  return (
    <div className={'d-none d-lg-block'} id='top'>
      <div className="container-xl pt-2 pb-2">
        <nav className={`${styles.topLinks} row d-flex`}>
          <div className="col-3">
            <div className="col-12 p-0 d-flex align-items-center">
              <img src={logoCloudofertas} alt="Logo do cloudofertas" width="85" height="70" />
            </div>
          </div>
          <div className="col-8 d-flex justify-content-between align-items-center">
            <div className="col-auto">
              <ScrollerLink
                to='sectionTopo'
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}>Home</ScrollerLink>
            </div>
            <div className="col-auto">
              <ScrollerLink
                to='sectionPlataforma'
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}>A plataforma</ScrollerLink>
            </div>
            <div className="col-auto">
              <ScrollerLink
                to='sectionBeneficios'
                spy={true}
                smooth={true}
                offset={-60}
                duration={499}>Benefícios</ScrollerLink>
            </div>
            <div className="col-auto">
              <ScrollerLink
                to='sectionClientes'
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}>Clientes</ScrollerLink>
            </div>
            <div className="col-4">
              <ScrollerLink
                to='sectionOrcamento'
                spy={true}
                smooth={true}
                offset={-60}
                duration={1200}
                className="btn btn-orange text-white w-100">Solicite um Orçamento</ScrollerLink>
            </div>
            <div className="col-2">
              <a href='https://sa.cloudofertas.com.br/#/login' target='blank' className="btn btn-gray w-100">Entrar</a>
            </div>
            {/* <div className="col-1 d-flex align-items-center justfify-content-end">
              <a role="button"
                href="https://api.whatsapp.com/send?=pt_BR&phone=5511950260071"
                target="_blank"
                rel="noopener noreferrer"><img src={whatsapp} alt="Icone de whatsapp" width="35" height="35" /></a>
            </div> */}
          </div>
        </nav>
      </div>
    </div>
  )
}

const Header = (data) => {
  // const [active, setActive] = useState(false)
  return (
    <header>
      <HeaderTop />
      {/* <HeaderMobile /> */}
    </header>
  )
}

export default Header
