import React, { useState } from 'react'
import './menuMobile.scss'
import { Link } from 'gatsby'
import { Link as ScrollerLink } from 'react-scroll'

// imagens
import logo from '../../images/header/logo-cloudofertas 1.svg'
import menu from '../../images/header/openButton.svg'
import fechar from '../../images/header/closeButton.svg'
// import whatsapp from '../../images/header/whatsapp.svg'

export default function headerMobile ({ location }) {
  // const [active, setActive] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  // eslint-disable-next-line no-unused-vars

  const handleMobileMenu = () => {
    setOpenMenu(!openMenu)
    if (!openMenu) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto'
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }

  React.useEffect(() => {
    console.log(openMenu)
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [])

  return (
    <>
      <header id="headerMobile" className="position-relative d-lg-none d-block">
        <nav className="menuHeight d-flex align-items-center justify-content-between px-4 pb-2 pt-2">
          <div className="logoMobile" ><Link aria-label="logohome" to="/"><img src={logo} alt="logo" width="105" height="60"/></Link></div>
          <div className="d-flex align-items-center">
            <div onClick={() => handleMobileMenu()} className="pl-3">
              <img src={openMenu ? fechar : menu } alt="Button" width="28" height="25"/>
            </div>
          </div>
        </nav>
        {
          openMenu && (
            <div className="heightMenuOpen bg-orange">
              <div className="px-4 h-auto">
                <div className="mb-sm-0 mb-md-4">
                  <ul className="pl-0">
                    <li className="d-block text-white">
                      <ScrollerLink
                        to='sectionTopo'
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500} className="text-white" onClick={() => handleMobileMenu()}>
                        Home
                      </ScrollerLink>
                    </li>
                    <li className="d-block text-white">
                      <ScrollerLink
                        to='sectionPlataforma'
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500} className="text-white" onClick={() => handleMobileMenu()}>
                        A Plataforma
                      </ScrollerLink>
                    </li>
                    <li className="d-block text-white">
                      <ScrollerLink
                        to='sectionBeneficios'
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500} className="text-white" onClick={() => handleMobileMenu()}>
                        benefícios
                      </ScrollerLink>
                    </li>
                    <li className="d-block text-white">
                      <ScrollerLink
                        to='sectionClientes'
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500} className="text-white" onClick={() => handleMobileMenu()}>
                        Clientes
                      </ScrollerLink>
                    </li>
                    <li className="d-block text-white">
                      <ScrollerLink
                        to='sectionOrcamento'
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500} className="text-white" onClick={() => handleMobileMenu()}>
                        Solicite um orçamento
                      </ScrollerLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )
        }
      </header>
    </>
  )
}
