import React from 'react'
// import { Link } from 'gatsby'
// import axios from 'axios'
// import InputMask from 'react-input-mask'

// styles
import './footer.scss'
// images
// import iconWhats from '../../images/icons/footer/WhatsApp.svg'

const Footer = () => {
  return (
    <>
      {/* <div className='bg-white'>
        <div className='container'>
          <div className='row pt-4 pb-4 pt-sm-5 '>
            <div className='col-2 d-none d-lg-flex flex-column custom-weightCadastro'>
              <Link to='/lojas/' className='pt-2 pb-2 '>Lojas</Link>
              <Link to='/ofertas/' className='pt-2 pb-2 '>Ofertas</Link>
              <Link to='/cartao-spani/' className='pt-2 pb-2 '>Cartão Spani</Link>
              <Link to='/trabalhe-conosco/' className='pt-2 pb-2 '>Trabalhe Conosco</Link>
            </div>
            <div className='col-2 d-none d-lg-flex flex-column custom-weightCadastro p-0'>
              <Link to='/aplicativo-spani/' className='pt-2 pb-2 '>Aplicativo Spani</Link>
              <Link to='/cartao-spani/' className='pt-2 pb-2 '>Atendimento</Link>
              <Link to='/grupo-zaragoza-spani/' className='pt-2 pb-2 custom-customWeight'>Grupo Zaragoza</Link>
              <Link to='/servicos/' className='pt-2 pb-2 custom-customWeight'>Serviços</Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className='container-fluid bg-orange footercustom'>
        <div className='row text-center'>
          <div className='col-12 footer-space custom-padding'>
            <p className='text-center text-white font-weight-regular mt-3 mb-3'>Copyright © 2021 | Por <a href="https://www.clouddog.com.br/" target="_blank" rel="noopener noreferrer" className="colorlink">CloudDog</a></p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Footer
